<template>
  <div>
    <div class="working-hours">
      <h5>Morning Shift</h5>
      <div class="day-stripe">
        <div class="day-hours-heading">Day</div>

        <div class="hours-open-div">
          <div class="day-hour-time-heading hours-open-div-wrapper">Open</div>
        </div>
        <div class="close-hr hours-open-div">
          <div class="day-hour-time-heading hours-open-div-wrapper">Close</div>
        </div>
      </div>
      <div
        class="day-stripe"
        v-for="schedule in userSchedule"
        :key="schedule.ws_id"
      >
        <div class="day-hours-div">
          <toggle-button v-model="schedule.status" :labels="true" />
          {{ schedule.ws_title }}
        </div>
        <div class="hours-open-div">
          <div class="hours-open-div-wrapper">
            <vue-timepicker
              v-model="schedule.start_time"
              format="hh:mm A"
              :minute-interval="5"
              close-on-complete
              placeholder="hh:mm a"
            ></vue-timepicker>
          </div>
        </div>
        <div class="close-hr hours-open-div">
          <div class="hours-open-div-wrapper pull-left">
            <vue-timepicker
              v-model="schedule.end_time"
              format="hh:mm A"
              :minute-interval="5"
              close-on-complete
              placeholder="hh:mm a"
            ></vue-timepicker>
          </div>
          <div class="pull-right time-error">
            <span
              :id="'errorMessage' + schedule.ws_id"
              style="display: none"
            ></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 text-right col-sm-12">
          <button class="rem-btn pull-right" v-on:click="updateSchedule">
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="working-hours">
      <h5>Evening Shift</h5>
      <div class="day-stripe">
        <div class="day-hours-heading">Day</div>

        <div class="hours-open-div">
          <div class="day-hour-time-heading hours-open-div-wrapper">Open</div>
        </div>
        <div class="close-hr hours-open-div">
          <div class="day-hour-time-heading hours-open-div-wrapper">Close</div>
        </div>
      </div>
      <div
        class="day-stripe"
        v-for="schedule in userScheduleEvening"
        :key="schedule"
      >
        <div class="day-hours-div">
          <toggle-button v-model="schedule.ws_isOpen" :labels="true" />
          {{ schedule.ws_title }}
        </div>
        <div class="hours-open-div">
          <div class="hours-open-div-wrapper">
            <vue-timepicker
              v-model="schedule.ws_start_time"
              format="hh:mm A"
              :minute-interval="5"
              close-on-complete
              placeholder="hh:mm a"
            ></vue-timepicker>
          </div>
        </div>
        <div class="close-hr hours-open-div">
          <div class="hours-open-div-wrapper pull-left">
            <vue-timepicker
              v-model="schedule.ws_end_time"
              format="hh:mm A"
              :minute-interval="5"
              close-on-complete
              placeholder="hh:mm a"
            ></vue-timepicker>
          </div>
          <div class="pull-right time-error">
            <span
              :id="'errorMessage' + schedule.ws_id"
              style="display: none"
            ></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 text-right col-sm-12">
          <button class="rem-btn pull-right" @click="updateUserEveningSchedule">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import axios from "axios";
import $ from "jquery";
export default {
  name: "weeklySchedule",
  components: {
    ToggleButton,
    VueTimepicker,
  },
  data() {
    return {
      userSchedule: [],
      userScheduleEvening: [],
      errorMsg: false,
      agent: "",
    };
  },
  created() {
    this.getSchedule();
    this.getScheduleEvening();
  },
  mounted() {},
  methods: {
    getSchedule() {
      if (this.$route.query.agent) {
        this.agent = this.$route.query.agent;
      } else {
        this.agent = "";
      }
      axios
        .get("schedule", {
          params: {
            user: this.agent,
          },

          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.userSchedule = res.data.success;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateSchedule() {
      if (this.$route.query.agent) {
        this.agent = this.$route.query.agent;
      } else {
        this.agent = "";
      }
      this.userSchedule.forEach((value) => {
        if (value.status == true) {
          var open = value.start_time;
          var close = value.end_time;

          if (
            open != "" &&
            open != null &&
            open != undefined &&
            open != "undefined" &&
            close != "" &&
            close != null &&
            close != undefined &&
            close != "undefined"
          ) {
            //convert both time into timestamp
            var stt = new Date("November 13, 2019 " + open);
            stt = stt.getTime();

            var endt = new Date("November 13, 2019 " + close);
            if (stt > endt) {
              $("#errorMessage" + value.ws_id).html(
                "Close time must be greater then start time."
              );
              $("#errorMessage" + value.ws_id).show();
              this.errorMsg = true;
            }else{
              $("#errorMessage" + value.ws_id).html("");
            }
            
          } else {
            $("#errorMessage" + value.ws_id).html("Enter Open & Close Time");
            $("#errorMessage" + value.ws_id).show();
            this.errorMsg = true;
          }
        }
      });
      if (!this.errorMsg) {
        axios
          .post(
            "update_schedule",
            { data: this.userSchedule, user: this.agent },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              if (res.data.user == "agent") {
                this.$router.push("/teams");
              } else {
                alert(res.data.success);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getScheduleEvening() {
      if (this.$route.query.agent) {
        this.agent = this.$route.query.agent;
      } else {
        this.agent = "";
      }
      axios
        .get("evening-schedule", {
          params: {
            user: this.agent,
          },

          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.userScheduleEvening = res.data.success;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUserEveningSchedule() {
      if (this.$route.query.agent) {
        this.agent = this.$route.query.agent;
      } else {
        this.agent = "";
      }
      this.userSchedule.forEach((value) => {
        if (value.ws_isOpen == true) {
          var open = value.ws_start_time;
          var close = value.ws_end_time;

          if (
            open != "" &&
            open != null &&
            open != undefined &&
            open != "undefined" &&
            close != "" &&
            close != null &&
            close != undefined &&
            close != "undefined"
          ) {
            //convert both time into timestamp
            var stt = new Date("November 13, 2019 " + open);
            stt = stt.getTime();

            var endt = new Date("November 13, 2019 " + close);
            if (stt > endt) {
              $("#errorMessage" + value.ws_id).html(
                "Close time must be greater then start time."
              );
              $("#errorMessage" + value.ws_id).show();
              this.errorMsg = true;
            }else{
               $("#errorMessage" + value.ws_id).html("");
            }
           
          } else {
            $("#errorMessage" + value.ws_id).html("Enter Open & Close Time");
            $("#errorMessage" + value.ws_id).show();
            this.errorMsg = true;
          }
        }
      });
      if (!this.errorMsg) {
        axios
          .post(
            "update_schedule_evening",
            { data: this.userScheduleEvening, user: this.agent },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              if (res.data.user == "agent") {
                this.$router.push("/members/agents");
              } else {
                alert(res.data.success);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style >
.working-hours {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 1.25rem;
}
.time-picker-overlay {
  outline: none;
}

.day-stripe {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.day-hours-div,
.day-hours-heading {
  width: 20%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
.day-hours-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.day-hours-heading,
.day-hour-time-heading {
  font-weight: 800;
}
.day-hour-time-heading {
  padding: 10px 18px !important;
}
.day-hours-div label.vue-js-switch {
  margin-right: 15px;
}
.day-hours-div .v-switch-label {
  text-transform: uppercase;
}
.hours-open-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.hours-open-div.close-hr {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  border-right-style: none;
}
.hours-open-div-wrapper {
  padding: 10px 12px;
  position: relative;
  display: block;
  width: 125px;
  margin-bottom: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.time-error {
  color: #eb6663;
  padding: 12px;
}

/* .hours-open-div-wrapper img{
    margin-top: 3px;
    width: 14px;
    position: absolute;
    right: 13px;
    top: 18px;
    opacity: 0.6;
    z-index: 1;
}
.working-hours{
    margin-top: 25px;
    border: 1px solid #0000001a;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,.2);
}
.switch-checkbox{
    margin-right: 15px;
    margin-bottom: 0;
} */
.vue__time-picker input.display-time {
  margin: 0;
  font-size: 18px;
  width: 100%;
  color: #353535;
  border: none;
  font-weight: 400;
  cursor: pointer;
  z-index: 2;
  background: #0000;
  cursor: pointer;
}
.vue__time-picker input.display-time:focus {
  border: none;
  outline: none;
  cursor: pointer;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
div.error-message-form,
div.plan-msg {
  position: absolute;
  background: #de2f2f;
  padding: 4px 8px;
  min-width: 160px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 0;
  color: #fff;
  left: 0px;
  z-index: 10;
  top: 65px;
  box-shadow: 0px 2px 3px -1px #000;
}
.input-group div.error-message-form {
  top: 33px;
  left: 30px;
}
div.error-message-form:before,
div.plan-msg:before {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(232, 232, 232, 0);
  border-bottom-color: #de2f2f;
  border-width: 7px;
  margin-left: -7px;
}
.error-message-form .fa-sort-asc {
  top: -9px !important;
  font-size: 27px !important;
  color: #e8e8e8 !important;
  left: 10px !important;
}
</style>
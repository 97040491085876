<template>
    <DashboardContainer>
        <WeeklySchedule/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import WeeklySchedule from '../components/settings/WeeklySchedule'

export default {
    name: 'weeklySchedule',
    components: {
        DashboardContainer,
        WeeklySchedule
    }
}
</script>